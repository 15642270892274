import React, { useState, Component } from "react";
import { getLocaleWiseLabel as t } from "../../../lib/localeUtils";
import { Container, Button, Form, Row, Col, Modal, Alert } from "react-bootstrap";
import styles from "./DisclaimerFrom.styles";
import * as firebase from "../../../lib/firebase";
import { connect } from "react-redux";
import { navigate } from "gatsby";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import clientConfig from "../../../../client-config";
import axios from "axios";

interface IncomingProps {
  labelArr: string[];
}

const DisclaimerForm: React.FC<IncomingProps> = ({ labelArr }: IncomingProps) => {
  const { companyName, userName, idNumber } = useSelector((state: RootState) => {
    try {
      return {
        companyName: state.system.companyName,
        userName: state.system.userName,
        idNumber: state.system.idNumber,
      };
    } catch (error) {
      return { companyName: "", userName: "", idNumber: "" };
    }
  });

  const [agree, setAgree] = React.useState(false);

  const submitHandler = async () => {
    let req = {
      uid: localStorage.userDetails ? JSON.parse(localStorage.userDetails).uid : "",
      currentPage: "/UploadDocumentsPage",
    };
    axios
      .post(`${clientConfig.backend}/api/v1/updateCurrentPage`, req)
      .then((res) => {
        console.log("res ", res);
        navigate("/UploadDocumentsPage");
      })
      .catch((error) => {
        console.log(error);
      });
    navigate("/UploadDocumentsPage");
  };

  // const { agree, userName, companyName, idNumber } = this.state;
  // const { labelArr } = this.props;
  // console.log('labelArr ',labelArr,userName);

  return (
    <Container as={Row} style={styles.loanContainer}>
      <div className="text-center">
        <h1>{t(labelArr, "disclaimerRequestPage_Title", "heb")}</h1>
      </div>
      <Row className="justify-content-md-center" style={{ paddingTop: "3%" }}>
        <Col md={20} className=" text-right">
          <div style={{ border: "2px solid #ccc", padding: "2%" }}>
            {t(labelArr, "disclaimer_part1", "heb") +
              " " +
              (userName ? userName + " " : "____") +
              t(labelArr, "disclaimer_part2", "heb") +
              (idNumber ? idNumber + " " : "___") +
              t(labelArr, "disclaimer_part3", "heb") +
              (companyName ? companyName + " " : "___") +
              t(labelArr, "disclaimer_part4", "heb")}
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6} style={{ display: "flex", padding: "2%", marginRight: "20%" }}>
          <Form.Label style={styles.checkBox}>{t(labelArr, "agree_Label", "heb")}</Form.Label>
          <Form.Check
            //className="ml-3"
            style={styles.checkBox}
            onChange={() => setAgree(!agree)}
            type="checkbox"
            name="formHorizontalRadios"
          />
          <Button
            onClick={submitHandler}
            disabled={!agree}
            variant="primary"
            type="submit"
            style={styles.btnNext}
          >
            {t(labelArr, "continue_Button", "heb") + " >"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DisclaimerForm;
