import React from "react";
const styles = {
  rtl: {
    direction: "rtl",
  } as React.CSSProperties,
  makeInline: {
    display: "flex",
  } as React.CSSProperties,
  loanContainer: {
    display: "flex",
    flexDirection: "column",
    direction: "rtl",
    justifyContent: "center",
  } as React.CSSProperties,
  legendSchedulerBorder: {
    fontSize: "120%",
    fontWeight: "bold",
    textAlign: "left",
    width: "fit-content",
    padding: "0 1%",
    borderBottom: "none",
  } as React.CSSProperties,
  btnNext: {
    height: "fit-content",
    marginRight: "4%",
  } as React.CSSProperties,
  checkBox: {
    marginTop: "2%",
  } as React.CSSProperties,
};

export default styles;
